import urljoin from "url-join";

/**
 * General purpose utility function used throughout the app
 */

/**
 * truncate a string to given length with an optional ellipsis at the end
 * @param {String} str - string to truncate
 * @param {Integer} len - desired length of the string
 * @param {Boolean} ellipsis - indicates if ellipsis should be added or not, defaults to true
 * @returns {*}
 */
const truncateStr = (str, len, ellipsis = true) => {
    if (str.length > len) {
        let newStr = str.substring(0, len);
        return ellipsis ? newStr + "..." : newStr;
    } else {
        return str;
    }
};

/**
 * Capitalize a string
 * @param {String} str string to be capitalised
 * @return {String}
 */
const cap = str => {
    if (str) {
        return str[0].toUpperCase() + str.slice(1, str.length);
    }

    return "";
};

/**
 * Ensure url starts with current site language prefix
 * @param {String} url string to modify if doesn't start with language prefix
 * @return {String}
 */
const includeLanguagePrefix = url => {
    const pathname = window.location.pathname;
    const langPrefixes = ["/nl-nl", "/nl-be", "/fr-be"];
    for (const i in langPrefixes) {
        const prefix = langPrefixes[i];
        if (pathname.startsWith(prefix) && !url.startsWith(prefix)) {
            return urljoin(prefix, url);
        }
    }
    return url;
};

export { truncateStr, cap, includeLanguagePrefix };
